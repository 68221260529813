import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Headroom from 'react-headroom'

import Header from "../components/header"
import SEO from "../components/seo"
import styles from './contact.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ContactPage extends React.Component {
    render(){
        return (
            <div>
                <SEO title="Contact" keywords={this.props.site.siteMetadata.keywords} />
                <div className={styles.header}>
                    <Headroom>
                        <Header />
                    </Headroom>
                </div>
                <div className={styles.container}>
                    <div className={styles.information}>
                        <div>
                            <h1>We would love to hear from you. Get in touch.</h1>
                        </div>
                        <div className={styles.details}>
                            <div className={styles.email}>
                                <h2><a href="mailto:contact@mindbuffer.net">{this.props.site.siteMetadata.email}</a></h2>
                            </div>
                            <h2>{this.props.site.siteMetadata.phoneNumber}</h2>
                            <br></br>
                            <div className={styles.address}>
                                <h2>{this.props.site.siteMetadata.address}</h2>
                                <h2>{this.props.site.siteMetadata.city} {this.props.site.siteMetadata.postcode}</h2>
                            </div>
                        </div>
                        <div className={styles.links}>
                            <a href="https://vimeo.com/mindbuffer" aria-label="Vimeo" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={['fab', 'vimeo']} size="1x" style={{ color: "#F7F7F7" }} />
                            </a>

                            <a href="https://soundcloud.com/mindbuffer" aria-label="Soundcloud" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={['fab', 'soundcloud']} size="1x" style={{ color: "#F7F7F7" }} />
                            </a>

                            <a href="https://www.instagram.com/mindbuffer/" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={['fab', 'instagram']} size="1x" style={{ color: "#F7F7F7" }} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                site {
                    siteMetadata {
                        author
                        email
                        phoneNumber
                        address
                        city
                        postcode
                        keywords
                    }
                }
            }
        `}
        render={(data) => (
            <ContactPage site={data.site} />
        )}
    />
)

